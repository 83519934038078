<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_da5d60a9f5f9537d')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <span class="" v-if="!isSel"> -->
					<el-button type="success" @click="openDioalog(null, $t('c0246c55b9cac963'))" size="small" icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>
					<!-- </span> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<li>
					<span>{{$t('hytxs0000060')}}</span>
					<cusSelFuzzy ref="cusSelFuzzy2" :size="'small'" @changeData="changCus2"></cusSelFuzzy>
					<!-- <cusSelFuzzyAll ref="cusSelFuzzy2" :size="'small'" @changeData="changCus2"></cusSelFuzzyAll> -->
				</li>
				<li>
					<span>LgsCode</span>
					<ExAcoLgsSelFuzzyAll ref="ExAcoLgsSelFuzzy2" :size="'small'" @changeData="changExLgs2"></ExAcoLgsSelFuzzyAll>
				</li>
				
				<li>
					<el-button icon="el-icon-search" size="small" type="primary"
						@click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- {"bizType":$t('i18nn_76dc341edac65885'),"opModel":$t('i18nn_c5400c966a9be32d'),"feeType":$t('46c3f6e0f657e7a3'),"feeTypeName":$t('i18nn_a4eca6ff2588d791')} -->

				<el-table-column prop="cusName" :label="$t('i18nn_18d7892498938435')" show-overflow-tooltip></el-table-column>
				
				<el-table-column prop="codeName" :label="$t('i18nn_179471a4a7edfc9e')"></el-table-column>
				<el-table-column prop="code" :label="'渠道code'"></el-table-column>
				<!-- <el-table-column prop="feeType" :label="$t('i18nn_94a6bbd4c69bda08')"></el-table-column> -->
				<el-table-column prop="feeTypeName" :label="$t('i18nn_3790f8e04246b942')"></el-table-column>
				<el-table-column prop="feePrice" :label="'数值(费用)'"></el-table-column>
				
				<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				
				<el-table-column prop="createTime" :label="$t('2594059036dfede5')" min-width="150">
					<template slot-scope="scope">
						<div>
							<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
							<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="userId" :label="'USER_ID'"></el-table-column>
				<el-table-column prop="lgsCodeId" :label="'渠道ID'"></el-table-column>
				
				<el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
					<template slot-scope="scope">
						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<div>
							<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
								icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
							<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
								icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<el-dialog :close-on-click-modal="false" :title="dialogFormMsg" append-to-body :visible.sync="dialogFormVisible"
			width="800px">
			<el-form ref="form" :rules="formRules" :model="form" label-width="150px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">

				<el-form-item :label="$t('i18nn_18d7892498938435')" required>
					<cusSelFuzzy ref="cusSelFuzzy" :size="''" @changeData="changCus"></cusSelFuzzy>
				</el-form-item>

				<el-form-item :label="'LgsCode'" required>
					<ExAcoLgsSelFuzzyAll ref="ExAcoLgsSelFuzzy" :size="''" @changeData="changExLgs"></ExAcoLgsSelFuzzyAll>
				</el-form-item>

				<el-form-item :label="$t('i18nn_3790f8e04246b942')" required>
					<el-select filterable clearable v-model="form.feeType" :placeholder="$t('2ad108ab2c560530')"
						style="width: 200px;">
						<el-option v-for="item in selectOption.lgs_thrid_rate_type" :key="item.code"
							:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>


				<el-form-item :label="'数值(费用)'" required>
					<!-- <el-input type="text" v-model="form.feePrice" style="width: 200px;"></el-input> -->
					<el-input-number v-model="form.feePrice" controls-position="right"></el-input-number>
				</el-form-item>


				<el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit style="width: 300px;"></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	import {
		clearObjectVal
	} from '@/utils/utils.js';
	import {
		getDicData
	} from '@/axios/common.js';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	// import AccountUserList from '@/components/WarehouseCenter2/AccountBind/AccountUserList.vue';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	// import barcode from '@/components/Common/barcode.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	
	// import cusSelFuzzyAll from '@/components/WarehouseCenter2/components/cusSelFuzzyAll.vue';
	

	import ExAcoLgsSelFuzzyAll from '@/components/WarehouseCenter2/components/ExAcoLgsSelFuzzyAll.vue';

	export default {

		// props: ['isDialog'],
		components: {
			// AccountUserList,
			// whNoSelect
			cusSelFuzzy,
			// cusSelFuzzyAll,
			ExAcoLgsSelFuzzyAll
		},
		// props:{
		//   // mobile:"",
		//   // isSel:{
		//   //   default: function() {
		//   //     return false
		//   //   },
		//   //   type: Boolean
		//   // },
		//   // status:{
		//   //   default: function() {
		//   //     return ''
		//   //   },
		//   //   type: String
		//   // },
		// },
		data() {
			return {
				// cusUserId: '',

				// UserInfo: this.$store.getters.getUserInfo,
				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改
				dialogFormMsg: '',
				// dialogSelVisible: false,

				// CateValue: [],
				loading: false,
				form: {
					"userId": "",
					"lgsCodeId": "",
					"lgsCode":"",
					"feeType": "",
					"feePrice": ""
				},

				formRules: {
					// serviceType: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }],
					// bizType: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }],
					// opModel: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }],
					// feeType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
					// feeTypeName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
				},
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					lgs_thrid_rate_type: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// userName: '',
					userId:"",
					lgsCodeId:""
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['lgs_thrid_rate_type'],
				(data) => {
					this.selectOption.lgs_thrid_rate_type = data['lgs_thrid_rate_type'];
				});

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
				// this.getWhNoListData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
			},

			changCus(data) {
				console.log("changCus", data);
				// this.filterData.cusNo = data.cusNo;
				this.form.userId = data.userId;
				// this.initData();
			},
			changExLgs(data) {
				console.log("changExLgs", data);
				// this.filterData.cusNo = data.cusNo;
				this.form.lgsCodeId = data.id;
				this.form.lgsCode = data.code;
				// this.initData();
			},
			
			changCus2(data) {
				console.log("changCus2", data);
				// this.filterData.cusNo = data.cusNo;
				this.filterData.userId = data.userId;
				this.initData();
			},
			changExLgs2(data) {
				console.log("changExLgs2", data);
				// this.filterData.cusNo = data.cusNo;
				this.filterData.lgsCodeId = data.id;
				this.initData();
			},

			//选择仓库
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },

			//打开新增编辑，弹窗
			openDioalog(formParm, msg) {
				// console.log(formParm);
				this.dialogFormVisible = true;
				this.dialogFormMsg = msg;
				// // 重置
				this.resetForm('form');
				if (null === formParm) {
					//新增
					this.dialogFormStatus = 0;
				} else {
					// 	//修改
					this.dialogFormStatus = 1;
					let form = Object.assign({}, formParm);
					console.log('form', form);
					this.form = form;
					this.$nextTick(()=>{
						this.$refs.cusSelFuzzy.init(this.form.userId);
						this.$refs.ExAcoLgsSelFuzzy.init(this.form.lgsCodeId);
					})
				}
			},
			//打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				this.openDioalog(row, this.$t('FormMsg.Edit'));
			},

			//删除
			delAction(event, row) {
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);
				// let parm = [];

				//   if (!!row) {
				//     //单条
				//     parm = [row.id];
				//   } else {
				//     //多条
				// //     let dataList = this.multipleSelection;
				// //     if (dataList.length < 1) {
				// //       this.$message.warning(this.$t('7b80e66b535a3732'));
				// //       return;
				// //     }

				// //     let dataListParm = dataList.map(v => v.id);
				// //     parm = dataListParm;
				//     // console.log('dataListParm', dataListParm);
				//     // let dataParm = {
				//     //   ids: dataListParm
				//     // };
				//   }

				this.postData(this.$urlConfig.WhLgsCusBindThridDel + '/' + row.id, {}, 'delete');
			},

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						if (0 === this.dialogFormStatus) {
							// formData.id = null;
							// formData.userName = null;

							this.postData(this.$urlConfig.WhLgsCusBindThridAdd+"/"+formData.userId, [formData]);
						} else {
							// formData.userId = this.UserInfo.id;
							// this.postData(this.$urlConfig.WhMyGoodsSkuUpdate+'/'+this.cusUserId, formData);
							this.postData(this.$urlConfig.WhLgsCusBindThridUpdate, formData);
						}
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				// console.log(this.$refs[formName]);

				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.form = clearObjectVal(this.form);
					this.$nextTick(()=>{
						this.$refs.cusSelFuzzy.init("");
						this.$refs.ExAcoLgsSelFuzzy.init("");
					})
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				// this.CateValue = [];
				// if (this.$refs.hyUpLoadImg1) {
				// 	this.$refs.hyUpLoadImg1.initUrl('');
				// }
			},

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.getPageData();
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						//   type: 'success',
						//   //confirmButtonText: this.$t('204ffab8a6e01870'),
						// });
						// this.$message.success(this.$t('tips.submitSuccess'));
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
			},

			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },

			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhLgsCusBindThridPage, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						lgsCodeId: this.filterData.lgsCodeId ? this.filterData.lgsCodeId : null,
						
						userId: this.filterData.userId ? this.filterData.userId : null,
						
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//请求
			// getWhNoListData() {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList, {})
			// 		.then(({ data }) => {
			// 			console.log('名称搜索，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_323ee425eca7208c'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('搜索，请求失败');
			// 			this.$message.error('请求失败！');
			// 			this.loading = false;
			// 		});
			// }
			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_biz_type', 'wh_op_type','wh_op_service_type'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// wh_biz_type    wh_op_type
			// 				this.selectOption.wh_biz_type = data.data['wh_biz_type'];
			// 				this.selectOption.wh_op_type = data.data['wh_op_type'];
			// 				this.selectOption.wh_op_service_type = data.data['wh_op_service_type'];

			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	@import url(@/assets/css/client_module.less);

	// /deep/ .el-input-number,
	//   .el-select {
	//     // width: 100px;
	//     .el-input__inner {
	//       text-align: left;
	//     }
	//   }
	// .form_msg {
	//   color: #e6a23c;
	// }
</style>